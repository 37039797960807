<ng-container *ngIf="currentURL() === '/signin' || currentURL() === '/health'">
  <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="_authService.isAuthenticated() && currentURL() !== '/signin'">
  <nz-layout class="app-layout">
    <nz-sider class="menu-sidebar"
              nzCollapsible
              nzWidth="256px"
              nzBreakpoint="md"
              [(nzCollapsed)]="isCollapsed"
              [nzTrigger]="null">
      <div class="sidebar-logo">
        <a href="/">
          <img src="assets/reuse-me_Logo_sw.svg" alt="wkk-logo">
          <h1>reuse.me</h1>
        </a>
      </div>
      <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
        <li nz-menu-item routerLinkActive="active">
          <span nz-icon nzType="stock" nzTheme="outline"></span>
          <span>dashboard</span>
          <a [routerLink]="'dashboard'"></a>
        </li>
        <li nz-menu-item routerLinkActive="active">
          <span nz-icon nzType="shop" nzTheme="fill"></span>
          <span>registration</span>
          <a [routerLink]="'registration'"></a>
        </li>
        <li nz-menu-item routerLinkActive="active">
          <i nz-icon nzType="shop"></i>
          <span>drop points</span>
          <a [routerLink]="'droppoints'"></a>
        </li>
        <li nz-menu-item routerLinkActive="active">
          <span nz-icon nzType="shop" nzTheme="fill"></span>
          <span>organization</span>
          <a [routerLink]="'organization'"></a>
        </li>
        <li nz-menu-item routerLinkActive="active">
          <span nz-icon nzType="file-text" nzTheme="outline"></span>
          <span>invoices</span>
          <a [routerLink]="'invoice'"></a>
        </li>
        <li nz-menu-item routerLinkActive="active">
          <i nz-icon nzType="number"></i>
          <span>id</span>
          <a [routerLink]="'number'"></a>
        </li>
        <li nz-menu-item routerLinkActive="active">
          <i nz-icon nzType="dropbox"></i>
          <span>status</span>
          <a [routerLink]="'carton'"></a>
        </li>
        <li nz-menu-item routerLinkActive="active">
          <i nz-icon nzType="inbox"></i>
          <span>tote</span>
          <a [routerLink]="'transport-aids'"></a>
        </li>
        <li nz-menu-item routerLinkActive="active">
          <i nz-icon nzType="carry-out"></i>
          <span>shipment</span>
          <a [routerLink]="'shipments'"></a>
        </li>
        <li nz-menu-item routerLinkActive="active">
          <i nz-icon nzType="user"></i>
          <span>app users</span>
          <a [routerLink]="'customer-users'"></a>
        </li>
        <li nz-menu-item routerLinkActive="active">
          <span nz-icon nzType="shop" nzTheme="fill"></span>
          <span>cron jobs</span>
          <a [routerLink]="'cronjobs'"></a>
        </li>
        <li nz-submenu nzTitle="administration" nzIcon="setting">
          <ul>
            <li nz-menu-item routerLinkActive="active">
              <i nz-icon nzType="user"></i>
              <span>profil</span>
              <a [routerLink]="'settings/profile'"></a>
            </li>
            <li nz-menu-item routerLinkActive="active">
              <span nz-icon nzType="unordered-list" nzTheme="outline"></span>
              <span>users</span>
              <a [routerLink]="'settings/user'"></a>
            </li>
          </ul>
        </li>
      </ul>
    </nz-sider>
    <nz-layout>
      <nz-header>
        <div class="app-header">
          <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <i class="trigger"
               nz-icon
               [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
            ></i>
          </span>
          <div style="float: right; margin-right: 24px">
            <button class="nz-btn-logout" nz-button nzType="primary" (click)="logout()"
                    nz-tooltip="" nzTooltipTitle="Abmelden" nzTooltipPlacement="bottom"
                    [nzTooltipMouseLeaveDelay]="0.3" [nzTooltipMouseEnterDelay]="0.7">
              <i nz-icon nzType="poweroff"></i>
            </button>
          </div>
        </div>
      </nz-header>
      <nz-content>
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>
      </nz-content>
    </nz-layout>
  </nz-layout>
</ng-container>
<ng-container *ngIf="!(currentURL() === '/signin' || currentURL() === '/health') && !_authService.isAuthenticated()">
  <span>logging out...</span>
</ng-container>
