import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APIMethod, APIRequestMethod} from './apiMethod';
import {
  AdminUser,
  Carton,
  CreateOrganization,
  CronJob,
  DropPoint,
  DropPointUser,
  GetCartonsResponse,
  GetDropPointsResponse,
  GetInvoicesResponse,
  GetNumberRangesResponse,
  GetTransportAidPrefixesResponse,
  GetTransportAidsResponse,
  Invoice,
  LabelPrefix,
  ListCronJobsResponse,
  ListCustomerUserResponse,
  ListOrganizationsResponse,
  NewAdminUser,
  NewRetailUser,
  NumberRange,
  Organization
} from "../../interfaces";
import {DropPointFormula} from "../../interfaces/DropPointFormula";


@Injectable({providedIn: 'root'})
export class ApiService {

  private static ENDPOINTS = {
    login:                        new APIMethod<{ token: string, user: AdminUser }>   (APIRequestMethod.POST,   'login'),
    refreshToken:                 new APIMethod<{ token: string }>                    (APIRequestMethod.GET,    'refresh'),

    createRetailStore:            new APIMethod<DropPoint>                          (APIRequestMethod.POST,   'drops'),
    getRetailStores:              new APIMethod<GetDropPointsResponse>              (APIRequestMethod.GET,    'drops'),
    getRetailStore:               new APIMethod<DropPoint>                          (APIRequestMethod.GET,    'drops/{sid}'),
    updateDrop:                  new APIMethod<DropPoint>                          (APIRequestMethod.PUT,    'drops/{sid}'),
    deleteDropPoint:                  new APIMethod<{status:string}>                          (APIRequestMethod.DELETE,    'drops/{sid}'),
    getUsersFromStore:            new APIMethod<{token: string; users: DropPointUser[]}> (APIRequestMethod.GET,    'drops/{sid}/users'),
    createStoreUser:              new APIMethod<DropPointUser>                           (APIRequestMethod.POST,   'drops/{sid}/users'),
    deleteDropPointUser:              new APIMethod<{ status: string }>                           (APIRequestMethod.DELETE,   'drops/{sid}/users/{dropPointUserId}'),
    resendDropPointUserCredentials:              new APIMethod<{ status: string }>                           (APIRequestMethod.POST,   'drops/{dropId}/users/{userId}/resend-credentials'),

    listNumberRanges:              new APIMethod<GetNumberRangesResponse>              (APIRequestMethod.GET,    'number-ranges'),
    createNumberRange:            new APIMethod<NumberRange>                          (APIRequestMethod.POST,   'number-ranges'),
    listNumberRangePrefixes:     new APIMethod<{ prefixes: LabelPrefix[] }>     (APIRequestMethod.GET,    'number-ranges/prefixes'),
    getNumberRange:               new APIMethod<NumberRange>                          (APIRequestMethod.GET,    'number-ranges/{sid}'),
    getCartons:                   new APIMethod<GetCartonsResponse>                   (APIRequestMethod.GET,    'cartons'),
    resetCarton:                  new APIMethod<Carton>                               (APIRequestMethod.PUT,    'cartons/{label}/reset'),
    listTotes:            new APIMethod<GetTransportAidsResponse>            (APIRequestMethod.GET,    'transport-aids'),
    createTransportBoxes:         new APIMethod<any>                                  (APIRequestMethod.POST,    'transport-aids'),
    listTransportAidPrefixes:     new APIMethod<GetTransportAidPrefixesResponse>     (APIRequestMethod.GET,    'transport-aids/prefixes'),
    resetTransportBox:            new APIMethod<any>                                  (APIRequestMethod.PUT,    'transport-aids/{label}/reset'),

    listOrganizations:             new APIMethod<ListOrganizationsResponse>            (APIRequestMethod.GET,    'organizations'),
    createOrganization:           new APIMethod<Organization>                         (APIRequestMethod.POST,   'organizations'),
    getOrganization:              new APIMethod<Organization>                         (APIRequestMethod.GET,    'organizations/{oid}'),
    getOrganizationStores:        new APIMethod<GetDropPointsResponse>              (APIRequestMethod.GET,    'organizations/{oid}/drops'),
    createOrganizationStore:      new APIMethod<DropPoint>                          (APIRequestMethod.POST,   'organizations/{oid}/drops'),
    listOrganizationBlobs:      new APIMethod<{token: string; users: Blob[]}>       (APIRequestMethod.GET,   'organizations/{oid}/blobs'),
    uploadOrganizationAttachment:      new APIMethod<Blob>       (APIRequestMethod.POST,   'organizations/{oid}/blobs'),

    getPanelUsers:                new APIMethod<{token: string; users: AdminUser[]}>   (APIRequestMethod.GET,   'admin-users'),
    createPanelUser:              new APIMethod<AdminUser>                            (APIRequestMethod.POST,   'admin-users'),

    getCurrentPanelUser:          new APIMethod<AdminUser>                            (APIRequestMethod.GET,    'admin-users/own'),
    updateCurrentPanelUser:       new APIMethod<AdminUser>                            (APIRequestMethod.PATCH,    'admin-users/own'),
    disablePanelUser:             new APIMethod<{id: string}>                         (APIRequestMethod.PUT,    'admin-users/{aid}/block'),
    enablePanelUser:              new APIMethod<{id: string}>                         (APIRequestMethod.PUT,    'admin-users/{aid}/unblock'),

    getInvoices:                   new APIMethod<GetInvoicesResponse>                  (APIRequestMethod.GET,    'invoices'),
    createServiceFeeInvoice:      new APIMethod<Invoice>                              (APIRequestMethod.POST,   'organizations/{oid}/invoices/serviceFee'),
    createHandlingsCostsInvoice:  new APIMethod<Invoice>                              (APIRequestMethod.POST,   'organizations/{oid}/invoices/handlingCosts'),
    createCircleCostsInvoice:     new APIMethod<Invoice>                              (APIRequestMethod.POST,   'organizations/{oid}/invoices/circleCosts'),
    createLicenceFees:            new APIMethod<Invoice>                              (APIRequestMethod.POST,   'organizations/{oid}/invoices/licenceFees'),

    getDropPointContactFormula:     new APIMethod<DropPointFormula> (APIRequestMethod.GET,   'formulas/{id}'),
    deleteDropPointContactForm:     new APIMethod<DropPointFormula> (APIRequestMethod.DELETE,   'formulas/{id}'),
    listDropPointContactForms:     new APIMethod<{forms: DropPointFormula[], nextPageToken: string}> (APIRequestMethod.GET,   'formulas'),
    updateDropPointContactForms:  new APIMethod<DropPointFormula>                        (APIRequestMethod.PUT,   'formulas/{id}'),
    acceptDropPointRegistration:  new APIMethod<DropPointFormula>                        (APIRequestMethod.POST,   'formulas/{id}/accept'),
    createDropPointFromRegistration:  new APIMethod<DropPointFormula>                        (APIRequestMethod.POST,   'formulas/{id}/drop'),
    sendDropPointContractByFormula:  new APIMethod<any>                        (APIRequestMethod.POST,   'formulas/{id}/contracts'),
    uploadDropPointFormulaAttachment:  new APIMethod<any>                        (APIRequestMethod.POST,   'formulas/{id}/attachments'),
    deleteDropRegistrationAttachment:  new APIMethod<any>                        (APIRequestMethod.DELETE,   'formulas/{id}/attachments/{bid}'),
    listDropPointFormulaAttachment:     new APIMethod<{blobs: any[], nextPageToken: string}> (APIRequestMethod.GET,   'formulas/{id}/attachments'),


    listCronJobs:                 new APIMethod<ListCronJobsResponse>                 (APIRequestMethod.GET,    'cronjobs'),
    enableCronJob:                new APIMethod<CronJob>                              (APIRequestMethod.PATCH,    'cronjobs/{jid}/enable'),
    disableCronJob:               new APIMethod<CronJob>                              (APIRequestMethod.PATCH,    'cronjobs/{jid}/disable'),

    listCustomerUsers: new APIMethod<ListCustomerUserResponse>(APIRequestMethod.GET,    'customer-users'),
    getCustomerJWT: new APIMethod<{ token: string}>(APIRequestMethod.GET,    'customer-users/{customerId}/jwt'),


    listShipments: new APIMethod<{ shipments: any[], nextPageToken: string}>(APIRequestMethod.GET, 'shipments'),



    // Statistic
    getActiveDropPoints: new APIMethod<{data: Array<{label: string, value: number}>}>(APIRequestMethod.GET, 'statistics/drops/active'),
    getReuseStatistics: new APIMethod<{data: Array<{label: string, value: number}>}>(APIRequestMethod.GET, 'statistics/cartons/reuses'),
    getCouponCount: new APIMethod<{count: number}>(APIRequestMethod.GET, 'statistics/coupons/count')

  };

  constructor(private http: HttpClient) {
  }

  login(mail: string, password: string) {
    return ApiService.ENDPOINTS.login.run(this.http, {}, {'mail': mail, 'password': password});
  }

  refreshToken() {
    return ApiService.ENDPOINTS.refreshToken.run(this.http, {}, {});
  }

  createStore(store: DropPoint) {
    return ApiService.ENDPOINTS.createRetailStore.run(this.http, {}, {
      mail: store.mail,
      name: store.name,
      address: {
        zip: store.address.zip,
        city: store.address.city,
        street: store.address.street,
        number: store.address.houseNumber,
      }
    });
  }

  getStores(token: string, params: {[key: string]: string}) {
    return ApiService.ENDPOINTS.getRetailStores.run(this.http, {token, ...params}, {});
  }

  getStore(sid: string) {
    return ApiService.ENDPOINTS.getRetailStore.run(this.http, {':sid': sid}, {});
  }

  updateDrop(id: string, params: { [p: string]: any}) {
    return ApiService.ENDPOINTS.updateDrop.run(this.http, {":sid": id}, params);
  }

  getUsersFromStore(sid: string) {
    return ApiService.ENDPOINTS.getUsersFromStore.run(this.http, {':sid': sid}, {});
  }

  createStoreUser(sid: string, user: Partial<NewRetailUser>) {
    return ApiService.ENDPOINTS.createStoreUser.run(this.http, {':sid': sid }, user);
  }

  listNumberRanges(token: string, params: any) {
    return ApiService.ENDPOINTS.listNumberRanges.run(this.http, {token, ...params}, {})
  }

  listNumberRangePrefixes() {
    return ApiService.ENDPOINTS.listNumberRangePrefixes.run(this.http, {}, {});
  }

  createNumberRanges(params: {organizationId: string; size: number; prefix: string }) {
    return ApiService.ENDPOINTS.createNumberRange.run(this.http, {}, params);
  }

  listCartons(token: string, params: {[p: string]: string}) {
    return ApiService.ENDPOINTS.getCartons.run(this.http,{token, ...params},{})
  }

  resetCarton(label: string) {
    return ApiService.ENDPOINTS.resetCarton.run(this.http,{":label": label},{})
  }

  listTotes(token: string, params: any) {
    return ApiService.ENDPOINTS.listTotes.run(this.http, {token, ...params}, {});
  }

  createTransportAids(params: { amount: number; prefix: string }) {
    return ApiService.ENDPOINTS.createTransportBoxes.run(this.http, {}, params);
  }

  listTransportAidsPrefixes() {
    return ApiService.ENDPOINTS.listTransportAidPrefixes.run(this.http, {}, {});
  }


  resetTransportBox(label: string) {
    return ApiService.ENDPOINTS.resetTransportBox.run(this.http,{":label": label},{})
  }

  listOrganizations(token: string, params: {[key: string]: any}) {
    return ApiService.ENDPOINTS.listOrganizations.run(this.http, { token, ...params }, {})
  }

  getOrganization(oid: string) {
    return ApiService.ENDPOINTS.getOrganization.run(this.http, { ':oid': oid }, {})
  }

  createOrganization(org: CreateOrganization) {
    return ApiService.ENDPOINTS.createOrganization.run(this.http, {}, {
      mail: org.mail,
      name: org.name,
      firstName: org.firstName,
      lastName: org.lastName,
      address: {
        zip: org.address!.zip,
        city: org.address!.city,
        street: org.address!.street,
        number: org.address!.houseNumber,
        country: org.address!.country,
      },
      createStore: org.createStore,
      storeType: org.storeType,
    });
  }

  getOrganizationStores(oid: string) {
    return ApiService.ENDPOINTS.getOrganizationStores.run(this.http, { ':oid': oid }, {})
  }

  createOrganizationStore(oid: string, rs: Partial<DropPoint>) {
    return ApiService.ENDPOINTS.createOrganizationStore.run(this.http, {':oid': oid}, {
      mail: rs.mail,
      name: rs.name,
      firstName: rs.firstName,
      lastName: rs.lastName,
      organizationId: oid,
      address: {
        zip: rs?.address?.zip,
        city: rs?.address?.city,
        street: rs?.address?.street,
        number: rs?.address?.houseNumber,
      },
      storeType: rs.storeType,
    });
  }

  getPanelUsers(token: string, pageSize: number) {
    return ApiService.ENDPOINTS.getPanelUsers.run(this.http, {'token': token, 'pageSize': pageSize}, {});
  }

  createPanelUser(user: Partial<NewAdminUser>) {
    return ApiService.ENDPOINTS.createPanelUser.run(this.http,{}, user);
  }

  getCurrentPanelUser() {
    return ApiService.ENDPOINTS.getCurrentPanelUser.run(this.http, {},{});
  }

  updateCurrentPanelUser(user: AdminUser | null) {
    return ApiService.ENDPOINTS.updateCurrentPanelUser.run(this.http, {},{
      firstName:  user?.firstName,
      lastName:  user?.lastName,
      mail:  user?.mail,
      address:{
        street: user?.address.street,
        number: user?.address.houseNumber,
        city: user?.address.city,
        zip: user?.address.zip,
        country: user?.address.country,
      },
    });
  }
  disablePanelUser(aid: string) {
    return ApiService.ENDPOINTS.disablePanelUser.run(this.http, {':aid': aid},{});
  }

  enablePanelUser(aid: string) {
    return ApiService.ENDPOINTS.enablePanelUser.run(this.http, {':aid': aid},{});
  }

  getInvoices(token: string, pageSize: number) {
    return ApiService.ENDPOINTS.getInvoices.run(this.http, {'token': token, 'pageSize': pageSize}, {})
  }

  createServiceFeeInvoice(oid: string, newNumCartons: number, serviceFee: number, startDate: Date, endDate: Date) {
    return ApiService.ENDPOINTS.createServiceFeeInvoice.run(this.http, {':oid': oid}, {
      numNewCartons: newNumCartons,
      serviceFee: serviceFee,
      startDate: startDate,
      endDate: endDate,
    });
  }

  createHandlingsCostsInvoice(oid: string, handlingFee: number, startDate: Date, endDate: Date) {
    return ApiService.ENDPOINTS.createHandlingsCostsInvoice.run(this.http, {':oid': oid}, {
      handlingFee: handlingFee,
      startDate: startDate,
      endDate: endDate,
    });
  }

  createCircleCostsInvoice(oid: string, shippingCosts: number, packingCosts: number, handlingFee: number, startDate: Date, endDate: Date) {
    return ApiService.ENDPOINTS.createCircleCostsInvoice.run(this.http, {':oid': oid}, {
      shippingCosts: shippingCosts,
      packingCosts: packingCosts,
      handlingFee: handlingFee,
      startDate: startDate,
      endDate: endDate,
    });
  }

  createLicenceFees(oid: string, startDate: Date, endDate: Date) {
    return ApiService.ENDPOINTS.createLicenceFees.run(this.http, {':oid': oid}, {
      startDate: startDate,
      endDate: endDate,
    });
  }

  getDropPointContactFormula(id: string) {
    return ApiService.ENDPOINTS.getDropPointContactFormula.run(this.http, {':id': id}, {});
  }
  listDropPointContactForms(token: string, params: {[key: string]: string}) {
    return ApiService.ENDPOINTS.listDropPointContactForms.run(this.http, {...params, token, 'pageSize': 1000}, {});
  }
  updateDropPointContactForm(id: string, params: Partial<DropPointFormula>) {
    return ApiService.ENDPOINTS.updateDropPointContactForms.run(this.http, {':id': id}, params);
  }

  acceptDropPointRegistration(id: string) {
    return ApiService.ENDPOINTS.acceptDropPointRegistration.run(this.http, {':id': id}, {});
  }

  deleteDropPointContactForm(id: string) {
    return ApiService.ENDPOINTS.deleteDropPointContactForm.run(this.http, {':id': id}, {});
  }

  createDropPointFromRegistration(id: string) {
    return ApiService.ENDPOINTS.createDropPointFromRegistration.run(this.http, {':id': id}, {});
  }
  uploadDropPointFormulaAttachment(id: string, params: any = {}) {
    return ApiService.ENDPOINTS.uploadDropPointFormulaAttachment.run(this.http, {':id': id}, params);
  }

  deleteDropRegistrationAttachment(regId: string, blobId: string) {
    return ApiService.ENDPOINTS.deleteDropRegistrationAttachment.run(this.http, {':id': regId, ':bid': blobId}, {});
  }

  sendDropPointContractByFormula(id: string, params: any = {}) {
    return ApiService.ENDPOINTS.sendDropPointContractByFormula.run(this.http, {':id': id}, params);
  }

  listDropPointFormulaAttachment(token: string, params: any = {}) {
    return ApiService.ENDPOINTS.listDropPointFormulaAttachment.run(this.http, {...params, 'token': token, 'pageSize': 20}, {});
  }

  listCronJobs(token: string) {
    return ApiService.ENDPOINTS.listCronJobs.run(this.http, {'token': token, 'pageSize': 20}, {});
  }

  enableCronJob(id: string) {
    return ApiService.ENDPOINTS.enableCronJob.run(this.http, {':jid': id}, {});
  }
  disableCronJob(id: string) {
    return ApiService.ENDPOINTS.disableCronJob.run(this.http, {':jid': id}, {});
  }

  listCustomerUsers(token: string, params: any = {}) {
    return ApiService.ENDPOINTS.listCustomerUsers.run(this.http, {...params, 'token': token, 'pageSize': 20}, {});
  }

  getCustomerUserJWT(id: string) {
    return ApiService.ENDPOINTS.getCustomerJWT.run(this.http, {':customerId': id }, {});
  }

  deleteDropPointUser({ userId, dropId }: { dropId: string; userId: string }) {
    return ApiService.ENDPOINTS.deleteDropPointUser.run(this.http, {':sid': dropId, ':dropPointUserId': userId }, {});
  }

  deleteDropPoint({ dropId }: { dropId: string;  }) {
    return ApiService.ENDPOINTS.deleteDropPoint.run(this.http, {':sid': dropId }, {});
  }

  listShipments(token: string, params: any)  {
    return ApiService.ENDPOINTS.listShipments.run(this.http, { ...params, 'token': token }, {});
  }

  resendDropPointUserCredentials(dropId: string, userId: string)  {
    return ApiService.ENDPOINTS.resendDropPointUserCredentials.run(this.http, { ':dropId': dropId, ':userId': userId }, {});
  }

  listOrganizationBlobs(token: string,  { orgId }: any)  {
    return ApiService.ENDPOINTS.listOrganizationBlobs.run(this.http, { token, ":oid": orgId }, {});
  }

  uploadOrganizationAttachment(id: string, params: any = {}) {
    return ApiService.ENDPOINTS.uploadOrganizationAttachment.run(this.http, {':oid': id}, params);
  }

  getActiveDropPoints() {
    return ApiService.ENDPOINTS.getActiveDropPoints.run(this.http, {}, {});
  }

  getReuseStatistics() {
    return ApiService.ENDPOINTS.getReuseStatistics.run(this.http, {}, {});
  }

  getCouponCount(params: {}) {
    return ApiService.ENDPOINTS.getCouponCount.run(this.http, params, {});
  }
}
