import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {ApiService} from "../../../../services/api/api.service";
import {NzMessageService} from "ng-zorro-antd/message";
import {AuthService} from "../../../../services/auth/auth.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'wkk-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  email: string = '';
  password: string = '';

  loading = false;

  sErr: string = "";

  constructor(private _apiService: ApiService,
              private _messageService: NzMessageService,
              private _authService: AuthService,
              private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _title: Title) {
  }

  ngOnInit(): void {
    this._title.setTitle(`${environment.applicationName} - Admin Panel - Login`);
  }

  async submitForm() {
      try {
        const data = await this._apiService.login(this.email, this.password);
        this._authService.setAuthCookie(data.token);
        if (this._authService.isAuthenticated()){
          await this._router.navigate(['']);
        }

      } catch (err:any) {
        switch ( err.status ) {
          case 400:
            this.sErr = "Anmeldedaten falsch, bitte erneut versuchen";
            break;
          case 500:
            this.sErr = "Es gab einen Fehler auf dem Server";
            break;
          default:
            this.sErr = "Ooops, etwas ist schief gelaufen!";
            break;
        }
        this._messageService.error(this.sErr);
      }
  }

}
