<nz-card style="width:400px; height: fit-content;" nzBorderless="true">
  <div style="width: 100%;">
    <img src="assets/reuse-me_Logo_sw.svg" alt="WKK"
         style="margin: auto; display: block; margin-bottom: 2em"/>


    <form (submit)="submitForm()">

      <nz-input-group nzPrefixIcon="user">
        <input nz-input type="email" placeholder="Benutzer" [(ngModel)]="email" [ngModelOptions]="{standalone: true}"/>
      </nz-input-group>

      <nz-input-group nzPrefixIcon="lock" style="margin-top: 1.5rem;">
        <input nz-input type="password" placeholder="Passwort" [(ngModel)]="password"
               [ngModelOptions]="{standalone: true}">
      </nz-input-group>

      <button nz-button nzType="primary" style="margin-top: 1.5rem; width: 100%;" [nzLoading]="loading">Anmelden</button>
    </form>
  </div>
</nz-card>
