import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SigninComponent } from './components/signin/signin.component';
import { NzCardModule } from "ng-zorro-antd/card";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzInputModule } from 'ng-zorro-antd/input';
import {SigninRoutingModule} from "./signin-routing.module";



@NgModule({
    declarations: [
        SigninComponent
    ],
    exports: [
        SigninComponent
    ],
  imports: [
    SigninRoutingModule,
    CommonModule,
    NzCardModule,
    NzButtonModule,
    NzFormModule,
    ReactiveFormsModule,
    NzInputModule,
    FormsModule,
  ]
})
export class SignInModule { }
