import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "./services/auth/auth.service";

@Component({
  selector: 'wkk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isCollapsed = false;

  constructor(public _router: Router,
              public _authService: AuthService){
  }

  currentURL(): string {
    return this._router.url.split('?')[0];
  }

  logout() {
    this._authService.deleteAllCookies();
    this._router.navigate(['/signin']).then(()=>{ });
  }
}
