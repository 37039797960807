import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from "./guards/auth/auth.guard";
import {HealthComponent} from "./modules/shared/api/health/health.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'registration',
  },
  {
    path: 'health',
    component: HealthComponent,
  },
  {
    path: 'signin',
    loadChildren: () => import('./modules/signin/signin.module').then(m => m.SignInModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'registration',
    loadChildren: () => import('./modules/drop-point-registration/drop-point-registration.module').then(m => m.DropPointRegistrationModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'organization',
    loadChildren: () => import('./modules/organization/organization.module').then(m => m.OrganizationModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'droppoints',
    loadChildren: () => import('./modules/drop-points/drop-points.module').then(m => m.DropPointsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'number',
    loadChildren: () => import('./modules/number-range/number-range.module').then(m => m.NumberRangeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'shipments',
    loadChildren: () => import('./modules/shipment/shipment.module').then(m => m.ShipmentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'carton',
    loadChildren: () => import('./modules/carton/carton.module').then(m => m.CartonModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'transport-aids',
    loadChildren: () => import('./modules/transport-aids/transport-aids.module').then(m => m.TransportAidsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'customer-users',
    loadChildren: () => import('./modules/customer-users/customer-users.module').then(m => m.CustomerUsersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'invoice',
    loadChildren: () => import('./modules/invoice/invoice.module').then(m => m.InvoiceModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'cronjobs',
    loadChildren: () => import('./modules/cron-jobs/cron-jobs.module').then(m => m.CronJobsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings/user',
    loadChildren: () => import('./modules/settings/user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings/profile',
    loadChildren: () => import('./modules/settings/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard],
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
