import {HttpClient} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';

export enum APIRequestMethod {'GET', 'POST', 'PUT', 'DELETE', 'PATCH'}

interface Parameter {
  [_:string]: string|number|boolean
}

export class APIMethod<T> {
  method: APIRequestMethod;
  uri: string;

  constructor(m: APIRequestMethod, u: string) {
    this.method = m;
    this.uri = u;
  }

  private rstr(): string {
    switch (this.method) {
      case APIRequestMethod.DELETE: return 'DELETE';
      case APIRequestMethod.GET:    return 'GET';
      case APIRequestMethod.POST:   return 'POST';
      case APIRequestMethod.PUT:    return 'PUT';
      case APIRequestMethod.PATCH:  return 'PATCH';
    }
  }

  run(http: HttpClient, params: Parameter, body: any|null): Promise<T> {
    let urlbase = this.uri;
    let urlext = '';

    let hasParams = false;
    if (params !== null) {
      for (const key of Object.keys(params)) {
        if (key.startsWith(':') && urlbase.includes('{'+key.substring(1)+'}')) {
          urlbase = urlbase.replace('{'+key.substring(1)+'}', `${params[key]}`);
          continue;
        }

        urlext += hasParams ? '&' : '?';
        urlext += key + '=' + encodeURIComponent(params[key]);
        hasParams = true;
      }
    }

    return lastValueFrom(http.request<T>(this.rstr(), urlbase+urlext, {body: body}))
  }

}
