import {AuthService} from '../auth/auth.service';
import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.getToken();
    let apiRequest;
    if (!req.url.startsWith('https://') && !req.url.startsWith('http://')) {
      apiRequest = req.clone({
        url: `${environment.apiBaseUrl}${req.url}`,
        headers: req.headers.set('Authorization', 'Bearer ' + token)
      });
    } else {
      apiRequest = req.clone();
    }
    return next.handle(apiRequest);
  }
}
