import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {environment} from "../../../environments/environment";

interface JWTClaims {
  id: string;
  mail: string;
  exp: number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  claimCache: [string, JWTClaims]|undefined = undefined;

  constructor(private cookie: CookieService) {
  }

  isAuthenticated(): boolean {
    return ((this.getClaimsFromToken()?.exp ?? 0) * 1000) > new Date().getTime();
  }

  setAuthCookie(accessToken: string): void {
    this.cookie.set(environment.cookieKey, accessToken, new JwtHelperService().decodeToken(accessToken).exp * 1000, '/', environment.cookieBaseUrl);
  }

  deleteAllCookies(): void {
    this.cookie.delete(environment.cookieKey, '/', environment.cookieBaseUrl);
  }

  getToken(): string {
    return this.cookie.get(environment.cookieKey);
  }

  getClaimsFromToken(): JWTClaims | null {
    const tok = this.getToken();
    if (this.claimCache !== undefined && tok === this.claimCache[0]) return this.claimCache[1];

    const claims = new JwtHelperService().decodeToken<JWTClaims>(tok)

    if (claims) this.claimCache = [tok, claims];

    return claims;
  }
}
